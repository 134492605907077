<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Describe how you would prepare
        <stemble-latex content="$500\,\text{mL}$" />
        of
        <stemble-latex content="$14.0\%$" />
        aqueous solution of silver nitrate. Silver nitrate is sold as a solid reagent.
      </p>
      <p class="mb-n2">
        <s-textarea v-model="inputs.explainedResponse" outlined />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'ChemUPEI2210A4Q5',
  components: {
    ChemicalLatex,
    StembleLatex,
    STextarea,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        explainedResponse: null,
      },
    };
  },
};
</script>
